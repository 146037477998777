<template>
    <div
        class="lightbox"
        :class="{ 'lightbox--open': lightBoxOpen }"
        @click.stop="closeLightBox($event)"
        ref="lightbox"
    >
        <div class="lightbox__content">
            <div class="lightbox__media" ref="lightboxMedia">
                <div
                    v-if="mediaType === 'image'"
                    class="lightbox__media-wrapper"
                    :class="{
                        'lightbox__media-wrapper--zoomed': zoomedIn,
                        'lightbox__media-wrapper--shrink': mediaHigherThanContext,
                    }"
                >
                    <div class="lightbox__image-wrapper" :class="{ 'lightbox__image-wrapper--zoomed': zoomedIn }">
                        <img
                            class="lightbox__image"
                            :class="{
                                'lightbox__image--landscape': mediaOrientation === 'landscape',
                                'lightbox__image--portrait': mediaOrientation === 'portrait',
                                'lightbox__image--context-landscape': orientation === 'landscape',
                                'lightbox__image--context-portrait': orientation === 'portrait',
                                'lightbox__image--higher-than-context': mediaHigherThanContext,
                                'lightbox__image--wider-than-context': mediaWiderThanContext,
                                'lightbox__image--original-size': zoomedIn,
                            }"
                            :style="originalSizeStyle"
                            :src="mediaUri"
                            @load="loading = false"
                            alt=""
                            ref="lightBoxMedia"
                        />
                    </div>

                    <button v-if="zoomedIn" class="lightbox__ui lightbox__zoom-out" @click="zoomOut"></button>

                    <button
                        v-if="mediaWiderThanContext || mediaHigherThanContext"
                        class="lightbox__ui lightbox__zoom-in"
                        :class="{ 'lightbox__zoom-in--disabled': zoomedIn }"
                        @click="zoomIn"
                    ></button>

                    <button class="lightbox__ui lightbox__close" @click.stop="closeLightBox()"></button>
                </div>

                <div v-if="mediaType === 'iframe'" class="lightbox__media-wrapper">
                    <iframe
                        :src="mediaUri"
                        loading="lazy"
                        :width="availableSpace.x"
                        :height="availableSpace.y"
                        style="border: 0"
                        referrerpolicy="no-referrer-when-downgrade"
                        ref="iframe"
                    >
                    </iframe>

                    <button class="lightbox__ui lightbox__close" @click.stop="closeLightBox()"></button>
                </div>

                <div
                    v-if="mediaType === 'html'"
                    class="lightbox__media-wrapper lightbox__media-wrapper--html"
                    ref="lightboxMediaWrapperHtml"
                    :key="htmlFrameKey"
                >
                    <div class="lightbox__html-container" v-html="renderHTML()" ref="htmlContainer"></div>

                    <button class="lightbox__ui lightbox__close" @click.stop="closeLightBox()"></button>
                </div>

                <div v-show="loading || !lightBoxContentReady" class="lightbox__loader">
                    <div class="lds-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { ResponsiveMixin } from "../../../js/_mixins/responsive";

export default {
    name: "content-lightbox",

    mixins: [ResponsiveMixin],

    data() {
        return {
            mediaUri: "",
            mediaType: "",

            lightBoxOpen: false,
            lightBoxContentReady: false,

            loading: true,

            mediaWidth: 0,
            mediaHeight: 0,
            mediaOrientation: "landscape",
            mediaHigherThanContext: false,
            mediaWiderThanContext: false,

            zoomedIn: false,

            keyCodes: {
                close: ["Esc", "Escape"],
            },

            preventCloseElements: ["IMG", "BUTTON", "IFRAME"],
            preventCloseParents: ["htmlContainer"],

            closeParents: ["lightbox"],
            closeElements: ["lightbox", "lightboxMedia", "lightboxMediaWrapperHtml"],

            img: null,

            contextHeightTolerance: 10,
            htmlFrameKey: 0,
            parentChildren: 0,
        };
    },

    computed: {
        originalSizeStyle() {
            return this.zoomedIn ? `width: ${this.mediaWidth}px; height: ${this.mediaHeight}px;` : "";
        },

        availableSpace() {
            const lightboxElComputedStyle = window.getComputedStyle(this.$refs["lightbox"], null);

            return {
                x:
                    this.windowWidth -
                    parseInt(lightboxElComputedStyle.getPropertyValue("padding-left"), 10) -
                    parseInt(lightboxElComputedStyle.getPropertyValue("padding-right"), 10),

                y:
                    this.windowHeight -
                    parseInt(lightboxElComputedStyle.getPropertyValue("padding-top"), 10) -
                    parseInt(lightboxElComputedStyle.getPropertyValue("padding-bottom"), 10),
            };
        },
    },

    methods: {
        forceRerender() {
            this.htmlFrameKey += 1;
        },

        getMediaDimensions() {
            /**
             * NOTE: Add other rules here for possible future media types
             */
            return new Promise((resolve, reject) => {
                if (this.mediaType === "image") {
                    this.img = new Image();

                    this.img.onload = () => {
                        this.mediaWidth = this.img.width;
                        this.mediaHeight = this.img.height;
                        resolve();
                    };

                    this.img.src = this.mediaUri;
                } else {
                    reject(`Media type of ${this.mediaUri} is currently not supported`);
                }
            });
        },

        async handleIframe() {
            this.$nextTick(() => {
                return new Promise((resolve, reject) => {
                    if (this.mediaType === "iframe") {
                        this.$refs.iframe.onload = () => {
                            this.loading = false;

                            resolve();
                        };
                    } else {
                        reject(`Media type of ${this.mediaUri} is currently not supported`);
                    }
                });
            });
        },

        renderHTML() {
            this.lightBoxContentReady = true;
            this.loading = false;

            return decodeURIComponent(this.mediaUri);
        },

        getMediaOrientation() {
            this.mediaOrientation = this.mediaHeight > this.mediaWidth ? "portrait" : "landscape";
        },

        handleMediaSize() {
            // we need to make sure height is not just one pixel larger
            this.mediaHigherThanContext = this.mediaHeight > this.availableSpace.y + this.contextHeightTolerance;
            this.mediaWiderThanContext = this.mediaWidth > this.availableSpace.x;
        },

        async getLightBoxData(event) {
            if (!event.detail.mediaUri) return;

            this.mediaUri = event.detail.mediaUri;
            this.mediaType = event.detail.mediaType || this.mediaType;

            if (this.mediaType === "image") {
                await this.getMediaDimensions();

                this.getMediaOrientation();
                this.handleMediaSize();
            }

            if (this.mediaType === "iframe") {
                await this.handleIframe();
            }

            this.lightBoxContentReady = true;
        },

        openLightBox() {
            document.documentElement.classList.add("overflow-y-hidden");
            this.lightBoxOpen = true;
        },

        getPreventCloseElementIntersection(targetElement) {
            return this.preventCloseParents.filter((preventCloseParent) => {
                return this.$refs[preventCloseParent] && targetElement === this.$refs[preventCloseParent];
            });
        },

        getCloseElementIntersection(targetElement) {
            return this.closeElements.filter((closeElement) => {
                return this.$refs[closeElement] && targetElement === this.$refs[closeElement];
            });
        },

        elementIsNotAllowedToClose(targetElement) {
            const clickedOutside =
                !(this.preventCloseElements.indexOf(targetElement.nodeName) > -1) &&
                !this.getPreventCloseElementIntersection(targetElement).length &&
                this.getCloseElementIntersection(targetElement).length;

            return !clickedOutside;
        },

        closeLightBox(event) {
            const clickedElement = event ? event.target : null;
            if (clickedElement && this.elementIsNotAllowedToClose(clickedElement)) return;

            if (clickedElement && this.mediaType === "html" && !clickedElement.classList.contains("lightbox__media"))
                return;

            this.lightBoxOpen = false;
            this.zoomOut();

            this.mediaUri = "";
            this.img = null;

            document.documentElement.classList.remove("overflow-y-hidden");
        },

        zoomIn() {
            this.zoomedIn = true;
        },

        zoomOut() {
            this.zoomedIn = false;
        },
    },

    mounted() {
        //Capture open event
        document.addEventListener("openLightBox", (event) => {
            this.getLightBoxData(event);
            this.openLightBox();
        });

        //close on 'escape'
        document.addEventListener("keyup", (event) => {
            if (this.keyCodes.close.indexOf(event.key) > -1) {
                this.closeLightBox();
            }
        });
    },
};
</script>
