<template>
    <div class="collapsible" :class="{ 'collapsible--open': opened, 'collapsible--disabled': disabled }">
        <div
            class="collapsible__title-bar grid-x clickable align-middle"
            :class="{
                'collapsible__title-bar--reverse': iconPosition.toLowerCase() === 'left',
            }"
            @click="toggle()"
        >
            <div class="collapsible__header-img cell small-12 medium-shrink" v-if="headerImg.length">
                <img :src="headerImg" alt="" />
            </div>

            <div class="collapsible__title cell auto">
                <slot name="title" />
            </div>

            <div v-if="activeIcon" class="collapsible__icon cell shrink">
                <span class="icon" :class="activeIcon"></span>
            </div>
        </div>

        <transition name="slide">
            <div v-show="opened || !ready" ref="content" class="collapsible__content text-left" :style="{ maxHeight }">
                <slot name="content" />
            </div>
        </transition>
    </div>
</template>

<script>
import { debounce } from "lodash";
import { mapGetters, mapActions } from "vuex";

import { COLLAPSIBLE_EVENTS } from "./constants";

export default {
    name: "CollapsibleContent",

    props: {
        title: String,

        icon: String,

        iconOpen: String,

        iconClosed: String,

        iconSize: {
            type: Number,
            default: 32,
        },

        iconPosition: {
            type: String,
            default: "right",
        },

        headerImg: {
            type: String,
            default: "",
        },

        isOpen: {
            type: Boolean,
            default: false,
        },

        collapsibleId: {
            type: Number,
            required: false,
        },

        accordionId: {
            type: Number,
            required: false,
        },

        collapseGroupMembers: {
            type: Boolean,
            required: false,
            default: false,
        },

        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },

        trackHistory: {
            type: Boolean,
            required: false,
            default: true,
        },

        reInit: {
            type: Boolean,
            required: false,
            default: false,
        },

        category: {
            type: String,
            required: false,
            default: "",
        },
    },

    data() {
        return {
            opened: !!this.isOpen,
            ready: false,
            maxHeight: "auto",
            videos: [],
            isInitialized: false,
            updateCollapsible: null,
        };
    },

    computed: {
        ...mapGetters("mainStore", ["getResizeAccordions", "accordionOpenIds"]),

        activeIcon() {
            if (this.iconClosed && !this.opened) {
                return `icon--${this.iconClosed}`;
            } else if (this.iconOpen && this.opened) {
                return `icon--${this.iconOpen}`;
            }

            return `icon--${this.icon}`;
        },

        accordionOpenId() {
            return `${this.accordionId}_${this.collapsibleId}`;
        },

        historyTracksCollapsible() {
            return this.trackHistory && window.history.state && "accordionOpenIds" in window.history.state;
        },
    },

    methods: {
        ...mapActions("mainStore", ["setAccordionOpen", "resizeAccordions"]),

        pauseVideos() {
            if (this.videos.length) {
                this.videos.forEach((video) => {
                    video.pause();
                });
            }
        },

        checkForVideos() {
            this.videos = [...this.$refs.content.getElementsByTagName("video")];
        },

        changeStatus(isOpen, eventName) {
            this.opened = isOpen;
            this.$emit("toggle", isOpen);

            if (eventName) {
                this.$emit(eventName);
            }
        },

        calcMaxHeight() {
            let height = 0;

            [...this.$refs.content.children].forEach((el) => {
                height += el.getBoundingClientRect().height;
            });

            this.maxHeight = `${height}px`;
        },

        trackStatus() {
            if (this.accordionId > -1 && this.collapsibleId > -1) {
                this.setAccordionOpen(this.accordionOpenId);
            }

            window.history.scrollRestoration = "auto";
            window.history.pushState(
                {
                    accordionOpenIds: this.accordionOpenIds,
                    currentState: window.history.length,
                },
                this.title
            );
        },

        open() {
            this.changeStatus(true, "opened");
            this.trackStatus();
        },

        close() {
            this.changeStatus(false, "closed");
            this.trackStatus();
        },

        toggle() {
            if (this.disabled) return;

            this.opened ? this.close() : this.open();
        },

        getAccordion(accordionElementOpenId) {
            const accordionId = accordionElementOpenId.split("_")[0];

            return parseInt(accordionId, 10);
        },

        getAccordionDiff(newVal, oldVal) {
            return new Promise((resolve) => {
                resolve(newVal.filter((accordionOpenId) => !oldVal.includes(accordionOpenId)));
            });
        },

        reInitCollapsible() {
            this.ready = false;

            this.updateCollapsible = window.setTimeout(() => {
                this.calcMaxHeight();
                this.ready = true;
                this.isInitialized = true;

                if (this.isOpen) {
                    this.open();
                }

                window.clearTimeout(this.updateCollapsible);
            }, 200);
        },
    },

    beforeMount() {
        if (this.historyTracksCollapsible) {
            if (window.history.state.accordionOpenIds.indexOf(this.accordionOpenId) > -1) {
                if (window.history.state.currentState < window.history.length) {
                    this.opened = true;
                } else {
                    window.history.scrollRestoration = "manual";
                }
            }
        }
    },

    mounted() {
        if (["left", "right"].indexOf(this.iconPosition) === -1) {
            // eslint-disable-next-line no-console
            console.error("unexpected icon-position (valid values: left or right)", this.iconPosition);
        }

        this.calcMaxHeight();
        this.ready = true;

        this.checkForVideos();

        this.$refs.content.addEventListener("transitionend", () => {
            if (this.opened) {
                this.calcMaxHeight();
            }
            if (!this.opened) {
                this.pauseVideos();
            }
        });

        if (this.opened) {
            this.open();
        }

        document.addEventListener(COLLAPSIBLE_EVENTS.openMobileFilters, () => {
            this.reInitCollapsible();
        });

        document.addEventListener(COLLAPSIBLE_EVENTS.openCollapsible, (event) => {
            if (!event.detail || event.detail !== this.category) return;

            this.open();
        });

        window.addEventListener(
            "resize",
            debounce(() => {
                if (!this.opened) return;

                this.reInitCollapsible();
            }, 120)
        );
    },

    watch: {
        accordionOpenIds(newVal, oldVal) {
            if (this.opened && this.collapseGroupMembers) {
                this.getAccordionDiff(newVal, oldVal).then((diff) => {
                    if (
                        diff.length &&
                        diff[0] !== this.accordionOpenId &&
                        this.getAccordion(diff[0]) === this.accordionId
                    ) {
                        this.close();
                    }
                });
            }
        },

        reInit() {
            if (this.reInit) {
                this.reInitCollapsible();
            }
        },

        isOpen() {
            if (!this.isOpen) {
                this.close();
            } else if (this.isInitialized) {
                this.open();
            }
        },

        getResizeAccordions() {
            if (this.getResizeAccordions) {
                this.calcMaxHeight();
                this.resizeAccordions(false);
            }
        },
    },
};
</script>
