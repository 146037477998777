var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"lightbox",staticClass:"lightbox",class:{ 'lightbox--open': _vm.lightBoxOpen },on:{"click":function($event){$event.stopPropagation();return _vm.closeLightBox($event)}}},[_c('div',{staticClass:"lightbox__content"},[_c('div',{ref:"lightboxMedia",staticClass:"lightbox__media"},[(_vm.mediaType === 'image')?_c('div',{staticClass:"lightbox__media-wrapper",class:{
                    'lightbox__media-wrapper--zoomed': _vm.zoomedIn,
                    'lightbox__media-wrapper--shrink': _vm.mediaHigherThanContext,
                }},[_c('div',{staticClass:"lightbox__image-wrapper",class:{ 'lightbox__image-wrapper--zoomed': _vm.zoomedIn }},[_c('img',{ref:"lightBoxMedia",staticClass:"lightbox__image",class:{
                            'lightbox__image--landscape': _vm.mediaOrientation === 'landscape',
                            'lightbox__image--portrait': _vm.mediaOrientation === 'portrait',
                            'lightbox__image--context-landscape': _vm.orientation === 'landscape',
                            'lightbox__image--context-portrait': _vm.orientation === 'portrait',
                            'lightbox__image--higher-than-context': _vm.mediaHigherThanContext,
                            'lightbox__image--wider-than-context': _vm.mediaWiderThanContext,
                            'lightbox__image--original-size': _vm.zoomedIn,
                        },style:(_vm.originalSizeStyle),attrs:{"src":_vm.mediaUri,"alt":""},on:{"load":function($event){_vm.loading = false}}})]),(_vm.zoomedIn)?_c('button',{staticClass:"lightbox__ui lightbox__zoom-out",on:{"click":_vm.zoomOut}}):_vm._e(),(_vm.mediaWiderThanContext || _vm.mediaHigherThanContext)?_c('button',{staticClass:"lightbox__ui lightbox__zoom-in",class:{ 'lightbox__zoom-in--disabled': _vm.zoomedIn },on:{"click":_vm.zoomIn}}):_vm._e(),_c('button',{staticClass:"lightbox__ui lightbox__close",on:{"click":function($event){$event.stopPropagation();return _vm.closeLightBox()}}})]):_vm._e(),(_vm.mediaType === 'iframe')?_c('div',{staticClass:"lightbox__media-wrapper"},[_c('iframe',{ref:"iframe",staticStyle:{"border":"0"},attrs:{"src":_vm.mediaUri,"loading":"lazy","width":_vm.availableSpace.x,"height":_vm.availableSpace.y,"referrerpolicy":"no-referrer-when-downgrade"}}),_c('button',{staticClass:"lightbox__ui lightbox__close",on:{"click":function($event){$event.stopPropagation();return _vm.closeLightBox()}}})]):_vm._e(),(_vm.mediaType === 'html')?_c('div',{key:_vm.htmlFrameKey,ref:"lightboxMediaWrapperHtml",staticClass:"lightbox__media-wrapper lightbox__media-wrapper--html"},[_c('div',{ref:"htmlContainer",staticClass:"lightbox__html-container",domProps:{"innerHTML":_vm._s(_vm.renderHTML())}}),_c('button',{staticClass:"lightbox__ui lightbox__close",on:{"click":function($event){$event.stopPropagation();return _vm.closeLightBox()}}})]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading || !_vm.lightBoxContentReady),expression:"loading || !lightBoxContentReady"}],staticClass:"lightbox__loader"},[_vm._m(0)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"lds-ring"},[_c('div'),_c('div'),_c('div'),_c('div')])
}]

export { render, staticRenderFns }