const JS_LINK_SELECTOR = "[data-js-link]";

/**
 * Make an element containing a link clickable
 *
 * @method
 */
export const jsLink = (el: Element): void => {
    if (!el) return;

    const linkEl = el.querySelector("a");

    if (!linkEl) return;

    const url = linkEl.href;

    el.addEventListener("click", () => {
        window.location.href = url;
    });
};

export const initJsLinks = (): void => {
    const jsLinkElements = document.querySelectorAll(JS_LINK_SELECTOR);

    if (!jsLinkElements) return;

    jsLinkElements.forEach((jsLinkElement) => {
        jsLink(jsLinkElement);
    });
};
