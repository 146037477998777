<template>
    <div class="contact-module__details" :class="{ 'contact-module--active': detailsOpen }">
        <a class="contact-module__btn" v-on:click.prevent="close()" href="#">{{ btnText }}</a>

        <div class="contact-wrapper">
            <div class="contact">
                <div class="contact__image" v-if="img">
                    <img :src="img" alt="" />
                </div>
                <div class="contact__details" :class="{ 'contact__details--margin': !img }">
                    <p class="contact__name" v-if="name">{{ name }}</p>
                    <p class="contact__department" v-if="department" v-html="department"></p>
                    <a class="contact__phone" :href="`tel:${phone}`" v-if="phone">
                        {{ phone }}
                    </a>
                    <a class="contact__mail" :href="`mailto:${mail}`" v-if="mail">
                        {{ mail }}
                    </a>
                    <a class="contact__link" :target="linkTarget" :href="link" v-if="link.length && linkText.length">
                        {{ linkText }}
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "contact-details",

    data() {
        return {
            detailsOpen: false,
        };
    },

    props: {
        btnText: {
            type: String,
            default: "Zurück",
        },
        img: {
            type: String,
        },
        name: {
            type: String,
        },
        department: {
            type: String,
        },
        phone: {
            type: String,
        },
        mail: {
            type: String,
        },
        linkText: {
            type: String,
        },
        link: {
            type: String,
        },
        linkTarget: {
            type: String,
            default: "_blank",
        },
        id: {
            type: String,
            required: true,
        },
        topicId: {
            type: String,
            required: true,
        },
    },

    computed: {
        ...mapGetters("contactStore", ["contactModuleOpen"]),
    },

    methods: {
        ...mapActions("contactStore", ["setContactTopic"]),

        close() {
            this.setContactTopic("contact_0");
        },
    },

    watch: {
        topicId: function () {
            this.detailsOpen = this.topicId === this.id;
        },

        contactModuleOpen: {
            handler() {
                if (!this.contactModuleOpen) {
                    this.close();
                }
            },
        },
    },
};
</script>
