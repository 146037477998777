<template>
    <div :class="{ 'drop-layer--open': opened }">
        <div class="navigation__sub-navi-wrapper">
            <div class="drop-layer__title-bar clickable" :class="{ active: opened }" @click="closeOpenSubNav($event)">
                <slot name="title"></slot>
            </div>

            <transition :name="transitionMode">
                <div
                    class="sub-navigation__container"
                    :class="{
                        'sub-navigation__container--slim': slim,
                        'sub-navigation__container--desktop': roomForNavigation,
                    }"
                >
                    <div class="grid-x grid-margin-x">
                        <div class="cell small-12">
                            <div class="grid-x grid-margin-x">
                                <div
                                    class="cell sub-navigation"
                                    :class="{ 'grid-x align-top': !slim, 'sub-navigation--mobile': !roomForNavigation }"
                                    v-show="!isDesktop || opened || !ready"
                                    ref="content"
                                    :style="{ maxHeight: maxHeight, visibility: visibility }"
                                >
                                    <div
                                        class="cell small-12 navigation__item navigation__item--back font-size--default"
                                        :class="{ 'xlarge-auto hide-for-xlarge': roomForNavigation }"
                                        @click.prevent="closeOpenSubNav($event)"
                                    >
                                        <a
                                            class="navigation__link navigation__link--back"
                                            href="#"
                                            :class="{ 'navigation__link--mobile': !roomForNavigation }"
                                        >
                                            <span>&nbsp;</span>
                                        </a>
                                    </div>
                                    <slot name="content" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ResponsiveMixin } from "../../../js/_mixins/responsive";

export default {
    name: "sub-navigation",

    mixins: [ResponsiveMixin],

    props: {
        title: String,

        slim: {
            type: Boolean,
            default: false,
        },
        isOpen: {
            type: Boolean,
            default: false,
        },
        idx: Number,
    },

    data() {
        return {
            opened: false,
            ready: false,
            maxHeight: "auto",
            visibility: "hidden",
            transitionMode: "pop",
        };
    },

    computed: {
        ...mapGetters("mainStore", ["activeIdx", "roomForNavigation"]),
    },

    mounted() {
        this.ready = true;
        this.calcHeight();
    },

    methods: {
        ...mapActions("mainStore", ["resetActiveIdx", "setActiveIdx", "setSubNavigationOpen"]),

        changeStatus(isOpen) {
            this.opened = isOpen;
        },

        calcHeight() {
            if (this.ready) {
                this.maxHeight =
                    this.transitionMode === "pop" ? "auto" : `${this.$refs.content.getBoundingClientRect().height}px`;
            }
        },

        open() {
            this.changeStatus(true, "opened");
        },

        close() {
            this.changeStatus(false, "closed");
        },

        toggle() {
            this.visibility = "visible";
            this.opened ? this.close() : this.open();
        },

        closeOpenSubNav(event) {
            if (!this.roomForNavigation) {
                event.preventDefault();

                if (this.activeIdx === this.idx) {
                    this.resetActiveIdx();
                } else {
                    this.setActiveIdx(this.idx);
                }
            }
        },
    },

    watch: {
        isOpen: function (newVal) {
            if (newVal) {
                this.setSubNavigationOpen(newVal);
            }

            this.toggle();
        },
    },
};
</script>
