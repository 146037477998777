<template>
    <div class="gallery">
        <div class="gallery__wrapper">
            <div ref="swiper-container" class="swiper-container">
                <div class="swiper-wrapper">
                    <slot name="slides" />
                </div>

                <span
                    ref="swiper-prev"
                    class="gallery__button-prev swiper-button-disabled"
                    tabindex="0"
                    role="button"
                    aria-label="Previous slide"
                    aria-disabled="true"
                    v-show="showNavigation"
                    >&nbsp;</span
                >

                <span
                    ref="swiper-next"
                    class="gallery__button-next"
                    tabindex="0"
                    role="button"
                    aria-label="Next slide"
                    aria-disabled="false"
                    v-show="showNavigation"
                    >&nbsp;</span
                >
            </div>

            <div class="gallery__pagination-wrapper" v-if="hasPagination && hasSlides">
                <div class="grid-container">
                    <div class="grid-x align-middle align-center">
                        <div class="cell shrink">
                            <div class="gallery__pagination" ref="swiper-pagination">&nbsp;</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper, { Navigation, Pagination, Autoplay } from "swiper";
import { MDCRipple } from "@material/ripple";
import { ResponsiveMixin } from "../../../js/_mixins/responsive";
import { getSwiperVideos, getCurrentVideo, currentSlideHasVideo } from "../../../js/_helpers/SwiperHelpers";

Swiper.use([Navigation, Pagination, Autoplay]);

export default {
    name: "gallery-swiper",

    mixins: [ResponsiveMixin],

    props: {
        options: {
            type: Object,
            default: null,
        },

        hasNavigation: {
            type: Boolean,
            default: false,
        },

        hasPagination: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            swiper: null,
            isInitialized: false,
            hasSlides: false,
            hasVideo: false,
            videoInView: false,
            showNavigation: false,
        };
    },

    mounted() {
        this.$el.querySelectorAll(".mdc-button").forEach((el) => {
            return new MDCRipple(el);
        });

        this.showNavigation = this.hasNavigation;

        if (this.$refs["swiper-container"].querySelectorAll("video")) {
            this.hasVideo = true;
        }

        if (this.$refs["swiper-container"].querySelectorAll(".gallery__slide").length > 1) {
            this.hasSlides = true;

            this.$nextTick(() => {
                this.initializeSwiper();
            });
        } else {
            this.showNavigation = false;
            this.videoInView = this.hasVideo;
        }
    },
    methods: {
        initializeSwiper() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const _comp = this;

            const navigation = this.showNavigation
                ? { prevEl: this.$refs["swiper-prev"], nextEl: this.$refs["swiper-next"] }
                : {};

            const swiperConfig = Object.assign(
                {
                    uniqueNavElements: false,
                    slidesPerView: 1,
                    speed: 600,

                    autoplay: {
                        delay: 10000,
                        disableOnInteraction: false,
                    },

                    navigation,

                    pagination: {
                        el: this.$refs["swiper-pagination"],
                        clickable: true,
                    },

                    on: {
                        init() {
                            if (_comp.hasVideo && !currentSlideHasVideo(this)) {
                                getSwiperVideos(this).forEach((video) => {
                                    video.pause();
                                });
                            }
                        },

                        transitionStart() {
                            if (_comp.hasVideo) {
                                getSwiperVideos(this).forEach((video) => {
                                    //TODO: Check this
                                    // video.addEventListener("pause", (event) => {});
                                    video.pause();
                                });
                            }
                        },

                        transitionEnd() {
                            if (currentSlideHasVideo(this)) {
                                getCurrentVideo(this)[0].play();
                            }
                        },
                    },
                },

                this.options
            );

            if (!this.isInitialized) {
                this.swiper = new Swiper(this.$refs["swiper-container"], swiperConfig);

                this.isInitialized = true;
            }
        },
    },
};
</script>
