<template>
    <button
        class="mdc-fab scroll-top-button"
        :class="[`floating-box__button--${floatingPosition}`, { active: scrollPositionOutsideViewport }]"
        @click="scrollToTop()"
    >
        <span class="mdc-fab__icon icon icon--NavigationChevronUp"></span>
    </button>
</template>

<script>
import { ResponsiveMixin } from "../../../js/_mixins/responsive";
import { ScrollPosition } from "../../../js/_mixins/scrollPosition";
import { mapGetters } from "vuex";

export default {
    name: "scroll-top-button",

    mixins: [ResponsiveMixin, ScrollPosition],

    props: {
        content: {
            type: String,
            default: "scroll top button",
        },
    },

    computed: {
        ...mapGetters("contactStore", ["contactModuleInitialized"]),

        floatingPosition() {
            return this.contactModuleInitialized ? 1 : 0;
        },
    },

    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        },
    },
};
</script>
