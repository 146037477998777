export const Phone = 1;
export const Tablet = 2;
export const Desktop = 3;

export const ResponsiveMixin = {
    data() {
        return {
            windowWidth: 0,
            windowHeight: 0,
            orientation: "landscape",
            breakpoints: {
                small: 0,
                medium: 640,
                large: 768,
                xlarge: 1024,
                xxlarge: 1280,
                xxxlarge: 1440,
                full: 1920,
            },
        };
    },

    mounted() {
        this.updateScreenProperties();

        window.addEventListener("resize", () => {
            this.updateScreenProperties();
        });
    },

    methods: {
        updateWindowWidth() {
            this.windowWidth = window.innerWidth;
        },

        updateWindowHeight() {
            this.windowHeight = window.innerHeight;
        },

        updateOrientation() {
            const higherThanWide = this.windowHeight > this.windowWidth;

            this.orientation = higherThanWide ? "portrait" : "landscape";
        },

        updateScreenProperties() {
            this.updateWindowWidth();
            this.updateWindowHeight();
            this.updateOrientation();
        },
    },

    computed: {
        isMobile() {
            return this.windowWidth < this.breakpoints.large;
        },

        isTablet() {
            return this.isMobile && !this.isSmall;
        },

        isSmall() {
            return this.windowWidth < this.breakpoints.medium;
        },

        isDesktop() {
            return this.windowWidth >= this.breakpoints.xlarge;
        },

        currentDevice() {
            if (this.isSmall) {
                return Phone;
            } else if (this.isTablet) {
                return Tablet;
            } else {
                return Desktop;
            }
        },
    },
};
