// Tablesaw init w/o jQuery needs to be disabled until further notice
// import Tablesaw from 'tablesaw';

export const initTabelsaw = () => {
    const rteElements = document.querySelectorAll(".rte");

    if (!rteElements) return;

    rteElements.forEach((rteElement) => {
        const tables = rteElement.querySelectorAll("table");

        if (!tables) return;

        tables.forEach((table) => {
            const tablesawWrapper = document.createElement("div");
            tablesawWrapper.classList.add("tablesaw-wrapper");

            table.parentNode.insertBefore(tablesawWrapper, table);

            tablesawWrapper.appendChild(table);

            // Tablesaw init w/o jQuery needs to be disabled until further notice
            // Tablesaw.init(tablesawWrapper);
        });
    });
};
