export const ScrollPosition = {
    computed: {
        scrollPositionOutsideViewport() {
            return this.lastKnownScrollPosition > this.windowHeight / 2;
        },

        isScrolledDown() {
            return this.lastKnownScrollPosition > 0;
        },
    },

    data() {
        return {
            lastKnownScrollPosition: 0,
            windowHeight: 0,
        };
    },

    mounted() {
        this.initializeWindowHeightListener();
        this.initializeScrollPositionListener();
    },

    methods: {
        getWindowHeight() {
            this.windowHeight = window.innerHeight;
        },

        initializeWindowHeightListener() {
            this.getWindowHeight();

            window.addEventListener("resize", () => {
                this.getWindowHeight();
            });
        },

        initializeScrollPositionListener() {
            window.addEventListener("scroll", () => {
                this.lastKnownScrollPosition = window.scrollY;
            });
        },
    },
};
