const getSwiperVideos = (swiper) => {
    return swiper.el.querySelectorAll("video");
};

const swiperHasVideo = (swiper) => {
    return getSwiperVideos(swiper).length;
};

const getCurrentVideo = (swiper) => {
    return swiper.slides[swiper.realIndex].querySelectorAll("video");
};

const currentSlideHasVideo = (swiper) => {
    return getCurrentVideo(swiper).length;
};

export { getSwiperVideos, currentSlideHasVideo, getCurrentVideo, swiperHasVideo };
