<template>
    <div class="contact-module">
        <div class="contact-module__head">
            <p class="head__title" v-html="title"></p>
        </div>
        <div class="contact-module__items">
            <!-- Loop for each topic start-->
            <div class="contact-module__topic" v-for="(topic, id) in contactData" :key="`${id}-title`">
                <a class="contact-title mdc-elevation--z3" v-on:click="setActiveId(id)" v-html="topic.title"></a>
            </div>
            <!-- Loop for each topic end-->
            <!-- Loop for each contact details start-->
            <contact-details
                v-for="(details, id) in contactData"
                :key="id"
                :btnText="details.btnText"
                :img="details.contact.img"
                :name="details.contact.name"
                :department="details.contact.department"
                :phone="details.contact.phone"
                :mail="details.contact.mail"
                :linkText="details.contact.linkText"
                :link="details.contact.link"
                :linkTarget="details.contact.linkTarget"
                :id="id"
                :topicId="activeId"
            >
            </contact-details>
            <!-- Loop for each contact details end -->
        </div>
    </div>
</template>

<script>
import ContactDetails from "./ContactDetails.vue";
import { mapGetters, mapActions } from "vuex";

export default {
    name: "contact-module",

    components: {
        ContactDetails,
    },

    props: {
        contacts: {
            type: Object,
            default: null,
        },
        title: {
            type: String,
            default: "Kontakt",
        },
    },

    data() {
        return {
            activeId: "contact_0",
            contactData: {},
        };
    },

    computed: {
        ...mapGetters("contactStore", ["contactTopic"]),
    },

    methods: {
        ...mapActions("contactStore", ["setContactTopic", "setContactModuleInitialized"]),

        setActiveId(id) {
            this.setContactTopic(id);
        },
    },

    mounted() {
        this.contactData = this.contacts;
        this.setContactModuleInitialized(true);
    },

    watch: {
        contactTopic: {
            handler() {
                this.activeId = this.contactTopic;
            },
        },
    },
};
</script>
