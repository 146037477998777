<template>
    <div class="gallery gallery--with-thumbs">
        <div class="grid-x grid-margin-x rte" v-if="$slots.rte">
            <div class="cell small-12 large-10">
                <slot name="rte"></slot>
            </div>
        </div>

        <div class="grid-x grid-margin-x">
            <div class="cell small-12 xlarge-auto">
                <div class="gallery__main">
                    <div ref="swiper-container" class="swiper-container">
                        <div class="swiper-wrapper">
                            <slot name="slides" />
                        </div>
                    </div>
                </div>
            </div>

            <div class="gallery__thumbs cell small-12">
                <div ref="thumb-swiper-container" class="swiper-container swiper-container--thumbs">
                    <div class="swiper-wrapper">
                        <slot name="thumb-slides" />
                    </div>
                </div>

                <span
                    ref="thumb-swiper-prev"
                    class="gallery__button-prev swiper-button-disabled show-for-xlarge"
                    tabIndex="0"
                    role="button"
                    aria-label="Previous slide"
                    aria-disabled="true"
                    v-show="showNavigation"
                >
                    &nbsp;
                </span>

                <span
                    ref="thumb-swiper-next"
                    class="gallery__button-next show-for-xlarge"
                    tabIndex="0"
                    role="button"
                    aria-label="Next slide"
                    aria-disabled="false"
                    v-show="showNavigation"
                >
                    &nbsp;
                </span>
            </div>
        </div>
    </div>
</template>

<script>
import Swiper, { Navigation, Autoplay, Thumbs } from "swiper";
import { MDCRipple } from "@material/ripple";
import { ResponsiveMixin } from "../../../js/_mixins/responsive";
import { getSwiperVideos, currentSlideHasVideo } from "../../../js/_helpers/SwiperHelpers";

Swiper.use([Navigation, Thumbs, Autoplay]);

export default {
    name: "gallery-swiper-with-thumbs",

    mixins: [ResponsiveMixin],

    props: {
        options: {
            type: Object,
            default: null,
        },

        hasNavigation: {
            type: Boolean,
            default: false,
        },

        hasPagination: {
            type: Boolean,
            default: true,
        },
    },

    data() {
        return {
            swiper: null,
            thumbSwiper: null,
            isInitialized: false,
            hasSlides: false,
            hasVideo: false,
            videoInView: false,
            showNavigation: false,
        };
    },

    computed: {
        thumbSwiperHeight() {
            return this.$refs["thumb-swiper-container"].offsetHeight;
        },

        thumbSwiperElementsHeight() {
            const thumbs = this.$refs["thumb-swiper-container"].querySelectorAll("img");
            let totalHeight = 0;

            thumbs.forEach((thumb) => {
                totalHeight += thumb.offsetHeight;
            });

            return totalHeight;
        },
    },

    mounted() {
        this.$el.querySelectorAll(".mdc-button").forEach((el) => {
            return new MDCRipple(el);
        });

        this.showNavigation = this.hasNavigation && this.thumbSwiperElementsHeight > this.thumbSwiperHeight;

        if (this.$refs["swiper-container"].querySelectorAll("video")) {
            this.hasVideo = true;
        }

        if (this.$refs["swiper-container"].querySelectorAll(".gallery__slide").length > 1) {
            this.hasSlides = true;

            this.$nextTick(() => {
                this.initializeSwiper();
            });
        } else {
            this.showNavigation = false;
            this.videoInView = this.hasVideo;
        }
    },

    methods: {
        initializeSwiper() {
            // eslint-disable-next-line @typescript-eslint/no-this-alias
            const _comp = this;

            const navigation = this.showNavigation
                ? { prevEl: this.$refs["thumb-swiper-prev"], nextEl: this.$refs["thumb-swiper-next"] }
                : {};

            if (!this.isInitialized) {
                const thumbSwiperConfig = Object.assign(
                    {
                        speed: 600,
                        freeMode: true,
                        watchSlidesProgress: true,
                        navigation,

                        breakpoints: {
                            0: {
                                direction: "horizontal",
                                slidesPerView: 2.5,
                            },

                            640: {
                                direction: "horizontal",
                                slidesPerView: 3.5,
                            },

                            1024: {
                                direction: "vertical",
                                slidesPerView: 6,
                                spaceBetween: 20,
                            },

                            1280: {
                                direction: "vertical",
                                slidesPerView: 6,
                                spaceBetween: 15,
                            },

                            1440: {
                                direction: "vertical",
                                slidesPerView: 5,
                                spaceBetween: 15,
                            },

                            1680: {
                                direction: "vertical",
                                slidesPerView: 5,
                                spaceBetween: 15,
                            },

                            1920: {
                                direction: "vertical",
                                slidesPerView: 5,
                                spaceBetween: 10,
                            },
                        },
                    },

                    this.options
                );

                this.thumbSwiper = new Swiper(this.$refs["thumb-swiper-container"], thumbSwiperConfig);

                const swiperConfig = Object.assign(
                    {
                        uniqueNavElements: false,
                        slidesPerView: 1,
                        speed: 600,

                        thumbs: {
                            swiper: this.thumbSwiper,
                        },

                        on: {
                            init() {
                                if (_comp.hasVideo && !currentSlideHasVideo(this)) {
                                    getSwiperVideos(this).forEach((video) => {
                                        video.pause();
                                    });
                                }
                            },

                            transitionStart() {
                                if (_comp.hasVideo) {
                                    getSwiperVideos(this).forEach((video) => {
                                        //TODO: Check this
                                        //video.addEventListener("pause", (event) => {});

                                        video.pause();
                                    });
                                }
                            },
                        },
                    },

                    this.options
                );

                this.swiper = new Swiper(this.$refs["swiper-container"], swiperConfig);
                this.isInitialized = true;
            }
        },
    },
};
</script>
