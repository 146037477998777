const getLightBoxData = (lightBoxEl) => {
    return {
        mediaUri: lightBoxEl.dataset.mediaUri || "",
        mediaType: lightBoxEl.dataset.mediaType || "image",
    };
};

const createLightBoxTrigger = (mediaType = "image") => {
    const lightBoxTrigger = mediaType === "image" ? document.createElement("button") : false;

    if (lightBoxTrigger) {
        lightBoxTrigger.classList.add("lightbox__ui");
        lightBoxTrigger.classList.add("lightbox__trigger");
    }

    return lightBoxTrigger;
};

export const initLightBoxElements = () => {
    const lightBoxElements = document.querySelectorAll('[data-js="lightbox"]');

    lightBoxElements.forEach((lightBoxElement) => {
        const lightBoxTrigger = createLightBoxTrigger(lightBoxElement.dataset.mediaType);
        const eventData = getLightBoxData(lightBoxElement);
        const lightboxEvent = new CustomEvent("openLightBox", { detail: eventData });

        if (lightBoxTrigger) {
            lightBoxElement.appendChild(lightBoxTrigger);

            lightBoxTrigger.addEventListener("click", () => {
                document.dispatchEvent(lightboxEvent);
            });
        } else {
            lightBoxElement.addEventListener("click", (event) => {
                event.preventDefault();
                document.dispatchEvent(lightboxEvent);
            });
        }
    });
};
