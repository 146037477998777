<template>
    <div class="grid-x grid-margin-x align-justify align-middle navigation__container">
        <slot name="navigation-content"></slot>
    </div>
</template>

<script>
import { ResponsiveMixin } from "../../../js/_mixins/responsive";

import { mapActions, mapGetters } from "vuex";
import SubNavigation from "../../molecules/navigation-sub/SubNavigation.vue";
import SiteSearch from "../../molecules/search/SiteSearch.vue";
import LoginLarge from "../login/LoginLarge.vue";

export default {
    name: "MainNavigation",

    mixins: [ResponsiveMixin],

    components: {
        //Components are needed for inline template
        // eslint-disable-next-line vue/no-unused-components
        SubNavigation,

        // eslint-disable-next-line vue/no-unused-components
        SiteSearch,

        // eslint-disable-next-line vue/no-unused-components
        LoginLarge,
    },

    data() {
        return {
            activeIndex: 0,
            closeTimeOut: null,

            subNavArea: {
                width: 0,
                height: 0,
            },

            mainNav: null,
            mainNavWidth: 0,
            mainNavElements: [],
            mainNavElementWidths: [],
            mainNavInnerWidth: null,

            metaNav: null,
            metaNavWidth: 0,
            metaNavIconWidth: 0,
            metaNavElements: [],
            metaNavElementWidths: [],
            metaNavInnerWidth: null,

            initialMobile: false,
        };
    },

    computed: {
        ...mapGetters("mainStore", [
            "activeIdx",
            "firstCollision",
            "secondCollision",
            "tippingPoint1",
            "roomForNavigation",
            "metaNavIconSize",
            "metaNavSpacingSize",
            "navSpacingSize",
            "subNavigationOpen",
            "SEARCH_IDX",
            "MYDSPACE_IDX",
            "LANGUAGES_IDX",
        ]),
    },

    methods: {
        ...mapActions("mainStore", [
            "setActiveIdx",
            "resetActiveIdx",
            "setFirstCollision",
            "setSecondCollision",
            "setSecondCollision",
            "setTippingPoint1",
            "setTippingPoint2",
            "setRoomForNavigation",
        ]),

        closeOpenSubNav(idx, event) {
            if (this.roomForNavigation) {
                if (this.activeIdx === idx && event.type === "mouseleave") {
                    const leaveElement = event.target.getElementsByClassName("sub-navigation__container")[0];
                    if (
                        event.clientY < leaveElement.getBoundingClientRect().top ||
                        event.clientY > leaveElement.offsetHeight ||
                        event.clientX < leaveElement.getBoundingClientRect().left ||
                        event.clientX > leaveElement.offsetWidth
                    ) {
                        this.closeTimeOut = window.setTimeout(() => {
                            this.resetActiveIdx();
                        }, 300);
                    }
                } else {
                    if (this.closeTimeOut) {
                        window.clearTimeout(this.closeTimeOut);
                    }

                    this.setActiveIdx(idx);
                }

                this.activeIndex = this.activeIdx;
            }
        },

        getNavigationElements() {
            return new Promise((resolve) => {
                this.mainNav = this.$refs["main-navigation"];
                this.metaNav = this.$refs["meta-navigation"];

                if (!this.mainNav) {
                    // eslint-disable-next-line no-console
                    console.error("Main navigation element not specified (missing ref='main-navigation'");

                    return;
                }

                this.mainNavElements = [].slice.call(this.mainNav.getElementsByClassName("navigation__item--main"));

                this.metaNavElements = this.metaNav
                    ? [].slice.call(this.metaNav.getElementsByClassName("navigation__item--main"))
                    : [];

                resolve();
            });
        },

        calculateNavWidthsMobile() {
            if (this.mainNav) {
                [...this.mainNav.getElementsByClassName("js-nav-element")].forEach((el) => {
                    this.mainNavWidth += el.getBoundingClientRect().width + this.navSpacingSize;
                });
            }

            if (this.metaNav) {
                [...this.metaNav.getElementsByClassName("js-nav-element")].forEach((el) => {
                    this.metaNavWidth +=
                        el.getBoundingClientRect().width + this.metaNavSpacingSize + this.metaNavIconSize;
                    this.metaNavIconWidth += this.metaNavSpacingSize + this.metaNavIconSize;
                });
            }

            this.setTippingPoint1(this.mainNavWidth + this.metaNavWidth);
            this.setTippingPoint2(this.mainNavWidth + this.metaNavIconWidth + 30);
        },

        calculateNavWidths() {
            this.mainNavWidth = this.mainNav ? this.mainNav.getBoundingClientRect().width : 0;
            this.metaNavWidth = this.metaNav ? this.metaNav.getBoundingClientRect().width : 0;

            this.mainNavElementWidths = this.mainNavElements.map((el) => el.getBoundingClientRect().width);
            this.metaNavElementWidths = this.metaNavElements.map((el) => el.getBoundingClientRect().width);

            this.mainNavInnerWidth = this.mainNavElementWidths.length
                ? this.mainNavElementWidths.reduce((prev, current) => {
                      return prev + current;
                  })
                : 0;

            this.metaNavInnerWidth = this.metaNavElementWidths.length
                ? this.metaNavElementWidths.reduce((prev, current) => {
                      return prev + current;
                  })
                : 0;
        },

        checkCollision() {
            if (!this.initialMobile) {
                this.calculateNavWidths();

                if (!this.tippingPoint1) {
                    this.setFirstCollision(this.mainNavWidth <= this.mainNavInnerWidth + 200);
                }

                this.setSecondCollision(this.tippingPoint1 && this.mainNavWidth <= this.mainNavInnerWidth + 200);
            }

            this.setRoomForNavigation(!this.isDesktop);

            if (window.innerWidth > this.tippingPoint1) {
                this.initialMobile = false;
            }
        },

        watchCollision() {
            window.addEventListener("resize", this.checkCollision);
        },
    },

    async mounted() {
        this.activeIndex = this.activeIdx;
        this.setRoomForNavigation(!this.isDesktop);

        this.initialMobile = !this.isDesktop;

        await this.getNavigationElements();

        this.calculateNavWidthsMobile();
        this.checkCollision();
        this.watchCollision();
    },

    watch: {
        firstCollision() {
            if (this.firstCollision === true) {
                this.setTippingPoint1(window.innerWidth);
            }
        },

        secondCollision() {
            if (this.secondCollision === true) {
                this.setTippingPoint2(window.innerWidth);
            }
        },
    },
};
</script>
