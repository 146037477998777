import Vue from "vue/dist/vue.min";
import Vuex from "vuex";

Vue.use(Vuex);

import "../../../scss-global/contens.scss";

import store from "../../../store/index";

import domLoaded from "../../../js/_helpers/domLoaded";

import CollapsibleContent from "../../../components/organisms/collapsible/CollapsibleContent";
import HeaderComponent from "../../../components/organisms/header/HeaderComponent.vue";
import GallerySwiper from "../../../components/organisms/gallery/GallerySwiper.vue";
import GallerySwiperWithThumbs from "../../../components/organisms/gallery/GallerySwiperWithThumbs.vue";
import FloatingBox from "../../../components/molecules/floating-box/FloatingBox.vue";
import ContactModule from "../../../components/organisms/contact-module/ContactModule.vue";
import ScrollTopButton from "../../../components/molecules/scroll-top-button/ScrollTopButton.vue";
import ContentLightbox from "../../../components/organisms/lightbox/ContentLightbox";

import { initLightBoxElements } from "../../../components/organisms/lightbox";

import { MDCTextField } from "@material/textfield";
import { MDCRipple } from "@material/ripple";

import { initTabelsaw } from "../../../components/organisms/tablesaw";

import { initJsLinks } from "../../../js/_utils/jsLink";

function init() {
    new Vue({
        el: "#header",
        store,
        components: { HeaderComponent },
    });

    new Vue({
        el: "#footer",
        store,
        components: { CollapsibleContent },
    });

    document.querySelectorAll('[data-vue="lightbox"]').forEach((el) => {
        new Vue({
            el,
            components: { ContentLightbox },
        });
    });

    document.querySelectorAll('[data-vue="collapsible"]').forEach((el) => {
        return new Vue({
            el,
            store,
            components: { CollapsibleContent },
        });
    });

    document.querySelectorAll('[data-vue="gallery"]').forEach((el) => {
        return new Vue({
            el,
            components: { GallerySwiper },
        });
    });

    document.querySelectorAll('[data-vue="gallery-with-thumbs"]').forEach((el) => {
        return new Vue({
            el,
            components: { GallerySwiperWithThumbs },
        });
    });

    document.querySelectorAll(".mdc-button").forEach((el) => {
        return new MDCRipple(el);
    });

    document.querySelectorAll(".mdc-text-field").forEach((el) => {
        return new MDCTextField(el);
    });

    document.querySelectorAll('[data-vue="contact"]').forEach((el) => {
        return new Vue({
            el,
            store,
            components: { FloatingBox, ContactModule, ScrollTopButton },
        });
    });

    initLightBoxElements();
    initTabelsaw();
    initJsLinks();
}

domLoaded(init);
