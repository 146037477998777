<template>
    <div class="floating-box" :class="{ 'floating-box--open': boxOpened }">
        <transition name="pop" v-on:before-enter="beforeEnter">
            <div
                class="floating-box__container mdc-elevation--z12"
                ref="box-container"
                :style="navigationMaxHeight"
                v-show="boxOpened"
            >
                <button
                    class="floating-box__close hide-for-large icon--background"
                    :class="iconClass"
                    @click="toggleBox()"
                ></button>
                <div class="floating-box__content">
                    <slot></slot>
                </div>
            </div>
        </transition>
        <button
            class="mdc-fab floating-box__button"
            :class="`floating-box__button--${floatingPosition}`"
            @click="toggleBox()"
        >
            <span class="mdc-fab__icon icon" :class="iconClass"></span>
            <span class="button__counter" v-if="counter > 0">{{ counter }}</span>
        </button>
    </div>
</template>

<script>
import { mapActions } from "vuex";
import { ResponsiveMixin } from "../../../js/_mixins/responsive";

export default {
    name: "floating-box",

    mixins: [ResponsiveMixin],

    props: {
        content: {
            type: String,
            default: "floating box",
        },

        icon: {
            type: String,
            default: "CommunicationChatSolid",
        },

        closeIcon: {
            type: String,
            default: "NavigationClose",
        },

        floatingPosition: {
            type: Number,
            default: 0,
        },

        counter: {
            type: Number,
            default: 0,
        },
    },

    data() {
        return {
            boxOpened: false,
            mainNavHeight: "auto",
            mainVisibility: "hidden",
            navigationMaxHeight: { "max-height": "auto", visibility: "hidden" },
            iconClass: "icon",
        };
    },

    mounted() {
        this.iconClass = `icon icon--${this.icon}`;
    },

    methods: {
        ...mapActions({
            setContactModuleOpen: "contactStore/setContactModuleOpen",
        }),

        toggleBox() {
            this.boxOpened = !this.boxOpened;
            this.setContactModuleOpen(this.boxOpened);
        },

        calcMaxHeight() {
            this.mainNavHeight = `${this.$refs.boxContainer.getBoundingClientRect().height}px`;
            this.navigationMaxHeight = { "max-height": this.mainNavHeight, visibility: this.mainVisibility };
        },

        beforeEnter: function () {
            this.mainVisibility = "visible";
            this.navigationMaxHeight = { "max-height": this.mainNavHeight, visibility: this.mainVisibility };
        },
    },

    watch: {
        boxOpened() {
            this.iconClass = this.boxOpened ? `icon icon--${this.closeIcon}` : `icon icon--${this.icon}`;
        },
    },
};
</script>
