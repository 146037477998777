<template>
    <form
        class="login grid-x grid-margin-x align-center align-top"
        method="post"
        :action="loginAction"
        ref="login-form"
    >
        <div class="login__text cell small-12 xlarge-6 xxlarge-4 grid-x grid-margin-x align-middle">
            <h3 class="cell small-12 color--accent3">{{ loginHeadline }}</h3>

            <p class="cell small-12">{{ loginText }}</p>

            <slot name="benefits"></slot>
        </div>

        <div class="login__form cell small-12 xlarge-6 xxlarge-4 xxlarge-offset-1">
            <div class="grid-x grid-margin-x align-middle">
                <div class="cell small-12 grid-x grid-margin-x" ref="login-inputs">
                    <div class="login__input cell small-12">
                        <label
                            class="mdc-text-field mdc-text-field--filled"
                            for="mydIdInput"
                            :class="{ 'mdc-text-field--invalid': !loginValid || !loginSuccessful }"
                        >
                            <span class="mdc-text-field__ripple"></span>

                            <span class="mdc-floating-label text--body2" id="mydId">{{ loginIdLabel }}</span>

                            <input
                                id="mydIdInput"
                                class="mdc-text-field__input"
                                type="text"
                                required
                                aria-labelledby="mydId"
                                tabindex="1"
                                name="dspaceid"
                                v-model="dsLogin"
                                @focus="hideError"
                                ref="myIdInput"
                            />

                            <span class="mdc-line-ripple"></span>
                        </label>

                        <div class="mdc-text-field-helper-line">
                            <div
                                class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
                                role="alert"
                                v-if="!loginValid"
                            >
                                <!---->{{ loginRequired
                                }}<!---->
                            </div>
                        </div>
                    </div>

                    <div class="login__input cell small-12">
                        <label
                            class="mdc-text-field mdc-text-field--filled"
                            for="mydIdPassword"
                            :class="{ 'mdc-text-field--invalid': !passwordValid || !loginSuccessful }"
                        >
                            <span class="mdc-text-field__ripple"></span>

                            <span class="mdc-floating-label text--body2" id="mydPass">{{ loginPasswordLabel }}</span>

                            <input
                                id="mydIdPassword"
                                class="mdc-text-field__input"
                                type="password"
                                required
                                aria-labelledby="mydPass"
                                tabindex="2"
                                name="passwort"
                                v-model="dsPassword"
                                @focus="hideError"
                                ref="myIdPass"
                            />

                            <input type="hidden" name="faua" value="login" />

                            <span class="mdc-line-ripple"></span>
                        </label>

                        <div class="mdc-text-field-helper-line">
                            <div
                                class="mdc-text-field-helper-text mdc-text-field-helper-text--persistent mdc-text-field-helper-text--validation-msg"
                                role="alert"
                                v-if="!passwordValid"
                            >
                                <!---->{{ loginRequired
                                }}<!---->
                            </div>
                        </div>
                    </div>

                    <div class="login__error cell small-12 grid-x grid-margin-x align-right" v-if="showSuccessError">
                        <p class="cell shrink">{{ loginSuccessfulError }}</p>
                    </div>

                    <div class="login__forget cell small-12 grid-x grid-margin-x align-right">
                        <p class="cell shrink">
                            <a :href="loginPasswordForgetLink" tabindex="4">{{ loginPasswordForget }}</a>
                        </p>
                    </div>

                    <div class="login__submit cell small-12">
                        <button
                            class="mdc-button mdc-button--unelevated mdc-button--secondary"
                            type="submit"
                            ref="login-button"
                            tabindex="3"
                            @click="submitForm"
                        >
                            <div class="mdc-button__ripple"></div>

                            <i class="icon icon--NavigationLongArrowRight mdc-button__icon" aria-hidden="true"></i>

                            <span class="mdc-button__label">{{ loginSubmit }}</span>
                        </button>

                        <p class="login__text">
                            {{ loginNoAccount }} <a :href="loginSignUpLink" tabindex="5">{{ loginSignUp }}</a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { MDCTextField } from "@material/textfield";
import { MDCRipple } from "@material/ripple";

import { ResponsiveMixin } from "../../../js/_mixins/responsive";

export default {
    name: "login-large",

    mixins: [ResponsiveMixin],

    props: {
        loginSuccessful: {
            type: Boolean,
            default: true,
        },

        loginSuccessfulError: {
            type: String,
            default: "Either your myDSPACE ID or your password is not correct!",
        },

        loginHeadline: {
            type: String,
            default: "Login to myDSPACE",
        },

        loginText: {
            type: String,
            default: "Als registrierter Nutzer profitieren Sie.",
        },

        loginIdLabel: {
            type: String,
            default: "myDSPACE ID",
        },

        loginPasswordLabel: {
            type: String,
            default: "Password",
        },

        loginPasswordForget: {
            type: String,
            default: "Forgot your password?",
        },

        loginPasswordForgetLink: {
            type: String,
            default: "/de/gmb/home/mydspace.cfm?fuseaction=resetpw",
        },

        loginSubmit: {
            type: String,
            default: "Login",
        },

        loginSignUp: {
            type: String,
            default: "Sign up",
        },

        loginSignUpLink: {
            type: String,
            default: "/de/gmb/home/mydspace.cfm?fuseaction=register",
        },

        loginNoAccount: {
            type: String,
            default: "Dont have an account?",
        },

        loginRequired: {
            type: String,
            default: "required",
        },

        loginAction: {
            type: String,
            default: "https://www.dspace.com/de/gmb/home.cfm",
        },
    },

    data() {
        return {
            loginInputs: [],
            loginButton: null,

            dsLogin: null,
            dsPassword: null,

            loginValid: true,
            passwordValid: true,

            showSuccessError: false,
        };
    },

    methods: {
        initMaterial() {
            return new Promise((resolve) => {
                this.loginInputs = [].map.call(
                    this.$refs["login-inputs"].querySelectorAll(".mdc-text-field"),
                    function (el) {
                        return new MDCTextField(el);
                    }
                );

                this.loginButton = new MDCRipple(this.$refs["login-button"]);

                resolve();
            });
        },

        checkForm() {
            return new Promise((resolve) => {
                this.loginValid = !!this.dsLogin;
                this.passwordValid = !!this.dsPassword;

                resolve(this.formValid());
            });
        },

        formValid() {
            return this.loginValid && this.passwordValid;
        },

        async submitForm() {
            const formValid = await this.checkForm();

            if (formValid) {
                this.$refs["login-form"].submit();
            }
        },

        hasValue(val) {
            return !!val.length;
        },

        hideError() {
            this.showSuccessError = false;
        },
    },

    async mounted() {
        await this.initMaterial();

        window.setTimeout(() => {
            this.loginInputs.forEach((input) => {
                input.foundation.adapter.floatLabel(true);
            });
        }, 200);

        this.showSuccessError = !this.loginSuccessful;
    },

    watch: {
        dsLogin: function (newVal) {
            this.loginValid = this.hasValue(newVal);
        },

        dsPassword: function (newVal) {
            this.passwordValid = this.hasValue(newVal);
        },

        loginSuccessful: function () {
            this.showSuccessError = !this.loginSuccessful;
        },
    },
};
</script>
